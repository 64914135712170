import moment from "moment";

moment.updateLocale("pt", {
  months: [
    "janeiro",
    "fevereiro",
    "março",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "setembro",
    "outubro",
    "novembro",
    "dezembro",
  ],
  monthsShort: [
    "jan",
    "fev",
    "mar",
    "abril",
    "maio",
    "junho",
    "julho",
    "agosto",
    "set",
    "out",
    "nov",
    "dez",
  ],
  monthsParseExact: true,
  weekdays: [
    "Segunda-Feira",
    "Terça-Feira",
    "Quarta-Feira",
    "Quinta-Feira",
    "Sexta-Feira",
  ],
  weekdaysShort: ["Seg", "Ter", "Qua", "Qui", "Sex"],
  weekdaysParseExact: true,
  calendar: {
    sameDay: "Hoje",
    nextDay: "Amanhã",
    nextWeek: "Na Próxima Semana",
    lastDay: "Ontem",
    lastWeek: "Na Semana Passada",
  },
  relativeTime: {
    future: "daqui a %s",
    past: "há %s",
    s: "alguns segundos",
    m: "um minuto",
    mm: "%d minutos",
    h: "uma hora",
    hh: "%d horas",
    d: "um dia",
    dd: "%d dias",
    M: "um mês",
    MM: "%d meses",
    y: "um ano",
    yy: "%d anos",
  },
  longDateFormat: {
    LT: "HH:mm",
    LTS: "HH:mm:ss",
    L: "D [de] MMMM",
    LL: "D [de] MMMM [de] YYYY",
    LLL: "D [de] MMMM [de] YYYY [às] HH:mm[H]",
    LLLL: `dddd, D [de] MMMM [de] YYYY, [às] HH:mm[H]`,
  },
});

export default moment;
