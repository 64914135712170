import moment from "../config/moment";

export function compareObjects(object1: any, object2: any) {
  if (!object1 && !object2) {
    return true;
  }
  if (object1 && object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
  } else {
    return false;
  }
}

export function zeroPad(num: number, places: number) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

export function returnCurrency(currency: any) {
  switch (currency) {
    case "EUR": {
      return "€";
    }
    case "USD": {
      return "$";
    }
    default:
      break;
  }
}

export function getInitials(name: string) {
  let names = name.split(" ").filter((obj: any) => obj && obj.length > 1);
  if (names.length > 1) {
    return names[0][0] + names[names.length - 1][0].toUpperCase();
  } else {
    return names.length > 0 ? names[0][0].toUpperCase() : "";
  }
}

export function getFirstLastName(name: string) {
  const fullName = name.split(' ');
  const first_name = fullName[0];
  const last_name = fullName[fullName.length - 1];

  return fullName.length == 1 ? { first_name: first_name, last_name: "" } : { first_name: first_name, last_name: last_name }
    
}

export function GetCustomDate(date1: any, date2: any) {
  const format = "YYYY-MM-DD";
  if (moment(date1, format).year() != moment(date2, format).year()) {
    return (
      moment(date1, format).format("LL") +
      " a " +
      moment(date2, format).format("LL")
    );
  } else if (
    moment(date1, format).month() != moment(date2, format).month() &&
    moment(date1, format).year() == moment(date2, format).year()
  ) {
    return (
      moment(date1, format).format("L") +
      " a " +
      moment(date2, format).format("LL")
    );
  } else {
    if (moment(date1, format).date() != moment(date2, format).date()) {
      return (
        moment(date1, format).date() +
        " a " +
        moment(date2, format).format("LL")
      );
    } else {
      return moment(date2, format).format("LL");
    }
  }
}

export function getYears(startYear: number) {
  const years = moment().diff(moment(`${startYear}-01-01`), "years");

  let options = [];
  for (let i = 0; i <= years; i++) {
    options.push({
      label: startYear + i,
      value: startYear + i,
    });
  }
  return options;
}

export function getHourFromMinutes(minutes: number) {
  const initialDay = moment.utc().startOf("day");
  const duration = moment.duration(minutes, "minutes");
  const hour = initialDay.add(duration).format("HH:mm");
  const final_hour = hour.replace(":", "h");
  return final_hour;
}
