import * as AllIcons from "@phosphor-icons/react";

export function GetIcon(icon: string) {
  try {
    const Icon: any = AllIcons[icon as keyof typeof AllIcons];

    if(!Icon){
      const DefaultIcon: any = AllIcons["TagSimple" as keyof typeof AllIcons];
      
      return DefaultIcon;
    }
    
    return Icon;
  } catch {
    return AllIcons.Warning;
  }
}
