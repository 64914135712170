import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    id: null,
    first_name: "",
    last_name: "",
    full_name: "",
    initials: "",
    email: "",
    phone: "",
    gender: "",
    user_ad: "",
    username: "",
  },
  reducers: {
    login(state: any, action: PayloadAction<any>) {
      state.token = action.payload.token;
    },

    saveInfo(state: any, action: PayloadAction<any>) {
      state.id = action.payload.id;
      state.first_name = action.payload.primeiro_nome;
      state.last_name = action.payload.ultimo_nome;
      state.email = action.payload.email;
      state.phone = action.payload.telemovel;
      state.initials = action.payload.iniciais;
      state.gender = action.payload.genero;
      state.full_name = action.payload.nome_completo;
      state.user_ad = action.payload.user_ad;
      state.username = action.payload.username;
    },

    logout(state: any) {
      state.token = null;
      state.id = null;
      state.first_name = "";
      state.last_name = "";
      state.full_name = "";
      state.email = "";
      state.phone = "";
      state.initials = "";
      state.gender = "";
      state.user_ad = "";
      state.username = "";
    },
  },
});

export const userActions = userSlice.actions;

export default userSlice;
