import { FC, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { useLayout } from "../../core";
import { toAbsoluteUrl } from "../../../helpers";
import { AsideMenu } from "./AsideMenu";
import { useSelector } from "react-redux";

const AsideDefault: FC = () => {
  const { classes, hideLateralMenu } = useLayout();
  const asideRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector((state: any) => state.user);

  const minimize = () => {
    asideRef.current?.classList.add("animating");
    setTimeout(() => {
      asideRef.current?.classList.remove("animating");
    }, 300);
  };

  useEffect(() => {
    hideLateralMenu(user.hide_lateral_menu == 1 ? true : false);
    minimize();
  }, [user.hide_lateral_menu]);

  return (
    <div
      id="kt_aside"
      className={clsx("aside", classes.aside.join(" "))}
      data-kt-drawer="true"
      data-kt-drawer-name="aside"
      data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true"
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction="start"
      data-kt-drawer-toggle="#kt_aside_mobile_toggle"
      ref={asideRef}
    >
      <div
        className="aside-logo flex-column-auto d-flex justify-content-center"
        id="kt_aside_logo"
      >
        <Link style={{ width: "80%" }} to="/home">
          <img
            alt="Logo"
            style={{ width: "100%" }}
            src={toAbsoluteUrl(`/media/logos/logo.png`)}
          />
        </Link>
      </div>
      <div className="aside-menu flex-column-fluid">
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
    </div>
  );
};

export { AsideDefault };
