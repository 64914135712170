import axios from "../config";

export const getUserMenus = (token: string) => {
  return axios.get(`/menus`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const getAllUserMenus = (token: string, user: any) => {
  return axios.get(`/menus/${user}`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const getAllMenus = (token: string) => {
  return axios.get("/menus/all", {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};

export const updateAllMenuUser = (token: string, user: any, body: any) => {
  return axios.post(`/menus/user?user=${user}`, body, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });
};
