import React from "react";
import clsx from "clsx";
import { AsideMenuItem } from "./AsideMenuItem";
import { GetIcon } from "../../../../../config/icons";
import { useLocation } from "react-router";

type Props = {
  menu: any;
};

const AsideMenuItemWithSub: React.FC<Props> = ({ menu }) => {
  const location = useLocation().pathname;
  let JSXIcon = menu.icone ? GetIcon(menu.icone) : null;

  function isActive() {
    if (location) {
      let menus = [menu];

      for (let i = 0; i < menus.length; i++) {
        if (menus[i].children) {
          menus.push(...menus[i].children);
        }
      }

      return menus.some(
        (obj: any) =>
          obj.path && obj.path != "/" && location.startsWith(obj.path)
      );
    } else {
      return false;
    }
  }

  return (
    <div
      className={clsx(
        "menu-item",
        {
          "here show": isActive(),
        },
        "menu-accordion"
      )}
      data-kt-menu-trigger="click"
    >
      <span className="menu-link">
        {JSXIcon ? (
          <span className="mr-2" style={{ position: "relative", top: "-2px" }}>
            <JSXIcon size={17} color="white" weight="fill" />
          </span>
        ) : (
          <span className="menu-bullet">
            <span className="bullet bullet-dot"></span>
          </span>
        )}
        <span className="menu-title">{menu.menu}</span>
        <span className="menu-arrow"></span>
      </span>
      <div
        className={clsx(`menu-sub menu-sub-accordion`, {
          "menu-active-bg": isActive(),
        })}
        id={"menu" + menu.id}
      >
        {menu.children.map((child: any) => {
          if (
            child.children &&
            child.children.some((child: any) => child.mostrar_menu)
          ) {
            return <AsideMenuItemWithSub menu={child} key={child.id} />;
          } else if (child.mostrar_menu) {
            return <AsideMenuItem menu={child} key={child.id} />;
          }
        })}
      </div>
    </div>
  );
};

export { AsideMenuItemWithSub };
