import clsx from "clsx";
import { FC, useEffect, useMemo, useState } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderNotificationsMenu, HeaderUserMenu } from "../../../partials";
import { useSelector } from "react-redux";
import { Bell } from "@phosphor-icons/react";
import UserPhoto from "../../../../UI/UserPhoto/UserPhoto";
import { getInitials } from "../../../../../utilities/utilities";

const toolbarButtonMarginClass = "pointer ml-5";

const Topbar: FC = () => {
  const user = useSelector((state: any) => state.user);

  const [notifications, setNotifications] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [image, setImage] = useState<any>();

  let interval: any;

  const notificationsToRead = useMemo(
    () => notifications.filter((item: any) => !item.lida).length,
    [notifications]
  );

  useEffect(() => {
    fetch(toAbsoluteUrl(`/uploads/users/${user.id}/profile.${user.img_ext}`))
      .then(async (image) => {
        const imageBlob = await image.blob();
        if (imageBlob.type.startsWith("image")) {
          setImage(URL.createObjectURL(imageBlob));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, []);

  function createInterval() {
    interval = setInterval(() => {
      //getNotifications();
    }, 2 * 60 * 1000);
  }

  // function getNotifications() {
  //   getUserNotifications(user.token).then((res) => {
  //     if (res.status === 200) {
  //       setNotifications(res.data);
  //     }
  //   });
  // }

  // function updateNotifications() {
  //   if (notificationsToRead) {
  //     readNotifications(user.token).then(() => {
  //       setNotifications(
  //         notifications.map((obj: any) => {
  //           return {
  //             ...obj,
  //             lida: 1,
  //           };
  //         })
  //       );
  //     });
  //   }
  // }

  useEffect(() => {
    //getNotifications();
    createInterval();
  }, []);

  return (
    <div className="d-flex align-items-center">
      {/* NOTIFICATIONS */}

      {/*<div className={clsx(toolbarButtonMarginClass)}>
        <div
          className={clsx(
            "d-flex justify-content-center align-items-center h-30px w-30px rounded bg-secondary text-primary"
          )}
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
          onClick={() => {
            //updateNotifications();
            clearInterval(interval);
            createInterval();
          }}
        >
          <Bell size={20} weight="regular" />
          {notificationsToRead ? (
            <span className="label label-rounded label-notification">
              {notificationsToRead}
            </span>
          ) : null}
        </div>

        <HeaderNotificationsMenu
          notifications={notifications}
          toRead={notificationsToRead}
        />
          </div>*/}

      {/* LANGUAGES */}

      {/* <div
        className={clsx(toolbarButtonMarginClass, "w-30px pointer")}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        <img
          src={toAbsoluteUrl(`/media/flags/${user.language}.svg`)}
          className="rounded"
        />
        <div
          className="menu menu-sub menu-sub-dropdown menu-column w-250px p-5 scroll-y mh-220px"
          data-kt-menu="true"
        >
          {languages.map((language: any, index: any) => {
            return (
              <div
                key={"language_" + index}
                className={clsx(
                  "rounded hover d-flex align-items-center",
                  index < languages.length - 1 && "mb-5"
                )}
                onClick={() => {
                  //updateUserLanguage(language);
                }}
              >
                <img
                  src={toAbsoluteUrl(`/media/flags/${language.idioma}.svg`)}
                  className="h-30px mr-3"
                />
                <p className="fs-6 fw-semibold">{language.descricao}</p>
              </div>
            );
          })}
        </div>
      </div> */}

      {/* USER */}
      <div
        className={clsx(toolbarButtonMarginClass)}
        id="kt_header_user_menu_toggle"
      >
        <div
          data-kt-menu-trigger="click"
          data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end"
          data-kt-menu-flip="bottom"
        >
          <UserPhoto
            user={user}
            initials={getInitials(user.full_name)}
            width={"100px"}
            size="fs-6 m-auto"
            symbol={"symbol-30px p-1"}
          />
        </div>

        <HeaderUserMenu />
      </div>
    </div>
  );
};

export { Topbar };
