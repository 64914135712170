import React, { useContext } from "react";
import Private from "./Private";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MasterLayout } from "../components/_metronic/layout/MasterLayout";
import { useSelector } from "react-redux";

//PAGES
import Login from "../pages/Auth/Login";

const PublicRoutes: React.FC = () => {
  const user = useSelector((state: any) => state.user);
  const userMenus = useSelector((state: any) => state.menus.menus);

  return (
    <BrowserRouter>
      <Routes>
        <Route>
          {user.id ? (
            <Route element={<MasterLayout />}>
              <Route path="*" element={<Private />} />
            </Route>
          ) : (
            <>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default PublicRoutes;
