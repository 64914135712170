import React, { Suspense, useContext, useEffect } from "react";
import PublicRoutes from "./routes/Public";
import { useDispatch, useSelector } from "react-redux";

import { LayoutContext } from "./components/_metronic/layout/core/LayoutProvider";
import FullPageLoading from "./components/UI/Loading/FullPageLoading";
import { userActions } from "./store/user";
import { menusActions } from "./store/menus";
import axios from "axios";

import { getInfo } from "./axios/requests/users";
import { getUserMenus } from "./axios/requests/menus";
import { AppDispatch } from "./store/store";

const App: React.FC = () => {
  const layoutProvider = useContext(LayoutContext);

  const dispatch = useDispatch<AppDispatch>();
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        dispatch(userActions.logout());
      }

      return Promise.reject(error);
    }
  );

  const user = useSelector((state: any) => state.user);
  const menus = useSelector((state: any) => state.menus.menus);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");

    if (!storedToken) {
      layoutProvider.setAppLoading(false);
    } else if (!user.token) {
      dispatch(userActions.login({ token: storedToken }));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (user.token && !user.id) {
      getInfo(user.token)
        .then((res: any) => {
          if (!layoutProvider.loading) {
            layoutProvider.setAppLoading(true);
          }
          dispatch(userActions.saveInfo(res.data));
        })
        .catch((err) => {
          if (err.response.status === 401) {
            localStorage.removeItem("token");
            layoutProvider.setAppLoading(false);
          }
        });
    } else if (user.id) {
      getUserMenus(user.token).then((res: any) => {
        dispatch(menusActions.save({ menus: res.data }));
      });
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (menus) {
      layoutProvider.setAppLoading(false);
    }
  }, [menus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Suspense fallback={<div></div>}>
      {/* <Theme /> */}
      {!layoutProvider.loading ? <PublicRoutes /> : <FullPageLoading />}
    </Suspense>
  );
};

export default App;
